import * as Sentry from '@sentry/react';

import {shouldIgnoreError} from './utils';

if (window.APP_CONFIG.sentry.dsn) {
  Sentry.init({
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    beforeSend: (event) => {
      return shouldIgnoreError(event) ? null : event;
    },
    environment: process.env.NODE_ENV,
    ...window.APP_CONFIG.sentry,
  });

  Sentry.setTag('host', window.location.host);
}
